import Fingerprint2 from 'fingerprintjs2';
import {client} from '../api/client.js';

export function finger() {
    return Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map(component => component.value); // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
        // console.log(components);
        // console.log(values);
        // console.log(murmur);
        client.save(murmur).then(r => {
        });
        localStorage.setItem('CLIENT_ID', murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
    });
}
